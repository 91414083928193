import React from 'react'
import ReactGA from 'react-ga'
import {videoArray} from '../data'

export let cache = null;
export let allow = true;
export let thisUserId = null;
export let videoindex = 0;
let activeUser = false;

export default async function fetcher(type, id){

    let GetUrl = process.env.REACT_APP_API + "/api/Get";
    let PostUrl = process.env.REACT_APP_API + "/api/Sold/Change";
    //let GetUrl = "http://localhost:3001/api/Get";
    //let PostUrl = "http://localhost:3001/api/Sold/Change"

    if(type === "GET"){
      if (!cache) {
          try{
            const GetResponse = await fetch(GetUrl);
            let data = await GetResponse.json();
            cache = String(data.answer).split(",")
          }catch(e){
            return e;
          }
        } 
        console.log(cache);
        return cache;
    }else if (type === "POST"){
      const settings = {
        method: 'POST',
        body: JSON.stringify({ itemID: id}),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      };
      try {
          const PostResponse = await fetch(PostUrl, settings);
          const data = await PostResponse.json();
          console.log(data);
          return data;
      } catch (e) {
          return e;
      }    
    }
    
}

function generateUserID(){
  if(thisUserId === null){
    thisUserId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    return thisUserId;
  }else{
    return thisUserId;
  }
}

export function initAnalytics(){
  if(activeUser === false){
    generateUserID();

    console.log(thisUserId);
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_UA,{
      debug: false,
      titleCase: false,
    });

    ReactGA.ga('create', process.env.REACT_APP_GOOGLE_UA, thisUserId); 
    ReactGA.ga('set', 'userId', thisUserId);
    ReactGA.pageview(window.location.pathname + window.location.search);
    activeUser = true;

  }else{
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
}

export function getRandom(){
  const min = 0;
  const max = videoArray.length + 1;
  let rand = min + Math.random() * (max - min);
  rand = Math.round(rand);
  if(videoArray[rand] === undefined){
      rand = rand-1;
  }
  
  return rand;
}

export function setVideoIndex(ind){
  videoindex = ind;
}

export function setAllowFalse(){
  return allow = false;
}

export function setAllowTrue(){
  return allow = true;
}

export function getAllow(){
  return allow;
}


