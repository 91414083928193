import React, { Component } from 'react'
import fetcher from './Components/fetcher.js';
import {productArray, productDetail, videoArray} from './data.js' 


//Provider.....Consumer
const ProductContext = React.createContext();

class ProductAPI extends Component{
    

    /*
    FUTURE UPDATE------USE REACT USESTATE/SETSTATE 
    DONT ARBIT CHANGE STATES
    */
    state = {
        loaded: true,
        products: [],
        productDetail: productDetail,
        cart: [],
        //cart: productArray,
        modelIsOpen: false,
        productModel: productDetail,
        cartSubTotal: 0.00,
        cartShip: 0.00,
        cartTotal: 0.00,
        Completed: false,
        redirectPage: false,
        UserConfirmed: false
    };
   
    AllGood = () =>{
        this.setState(()=>{return{UserConfirmed: true}});
        return true;
    }

    getOkay = () => {
        return this.state.UserConfirmed
    }

    redirect = (href) =>{
        if(this.state.redirectPage === false){
            this.setState(()=>{return{redirectPage: true}});
            //window.location.target = "_blank";
            window.location.href = href;
        }
    };
    
    //override method
    componentWillMount(){
        this.timer = setInterval(
            () => this.setState(prevState => ({loaded: true})),60000);
    }

    componentDidMount(){
        this.setProducts();
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

      
    transCompleted = () =>{
        this.state.cart.forEach(index=>{
            fetcher("POST", index.id);
            //console.log(index.id);
        });

        this.setState(()=>{
              return{Completed: true};
        });
    }

    //set product list state
    setProducts = () =>{
        let temp = [];
        productArray.forEach(item=>{
            const singleItem = {...item};
            temp = [...temp, singleItem];
        });
        this.setState(() =>{
            return { products: temp};
        });
    };
    
    getItem = id => {
        const item = this.state.products.find(item => item.id === id);
        return item;
    };

    handleDetail = id => {
        const item = this.getItem(id);
        this.setState(() =>{
            return { productDetail : item};
        });
    };

    clearCurrentItem = () =>{
        return null;
    }

    openModel = id =>{
        const item = this.getItem(id);
        console.log(item.inCart);
        if(item.canOpenModel){
            const item = this.getItem(id);
            this.setState(() =>{
                item.canOpenModel = false;
                return{productModel:item, modelIsOpen:true}
            });
        }else{
            return null;
        }
    }

    closeModel = () =>{
        this.setState(() => {
            return{modelIsOpen:false}
        });
    }
    
    addToCart = id => {        
        
        let temp = [...this.state.products];
        const index = temp.indexOf(this.getItem(id));
        const item = temp[index];

        if(item.inCart){
            alert("Item is already in your cart.");
            return null;
        }else{

            //TOTAL HANDLING
            this.state.cartSubTotal += item.price;
            if(this.state.cartSubTotal >= 100){
                this.state.cartShip = 0
            }else{
                this.state.cartShip = 4;
            }
            this.state.cartTotal = (this.state.cartSubTotal + this.state.cartShip);

            item.inCart = true;
            item.count = 1;
            const price = item.price;
            item.total = price;

            this.setState(()=>{
                return{products: temp, cart:[...this.state.cart, item]};
            },()=>{console.log(this.state)});
        }
    };

    //FIX THIS
    removeItem = id =>{
        let temp = [...this.state.cart];
        const index = temp.indexOf(this.getItem(id));
        const item = temp[index];
        
        this.state.cartSubTotal -= item.price;
        if(this.state.cartSubTotal >= 100){
            this.state.cartShip = 0
        }else{
            this.state.cartShip = 4;
        }
        this.state.cartTotal = (this.state.cartSubTotal + this.state.cartShip);

        this.state.products[id].inCart = false;
        this.state.products[id].canOpenModel = true;
        
        console.log(this.state.products);
        temp.pop(id);
        this.setState(()=>{
            return{cart: temp};
        })

        console.log(this.state.cart);
    }

    clearCart = () =>{
        alert("Your cart has been cleared.");
        this.state.cartSubTotal = 0;
        this.state.cartTotal = 0;
        this.state.cartShip = 0;
        this.state.products.forEach(item => {
            item.inCart = false;
            item.canOpenModel = true;
        });
        this.setState(()=>{
            return{cart: []};
        });
    }

    //client values
    render(){
        //console.log(this.state.productDetail);
        return(
            <ProductContext.Provider value = {{
                ...this.state,
                handleDetail: this.handleDetail,
                addToCart: this.addToCart,
                getItem: this.getItem,
                openModel: this.openModel,
                closeModel: this.closeModel,
                removeItem: this.removeItem,
                clearCart: this.clearCart,
                transCompleted: this.transCompleted,
                redirect: this.redirect,
                AllGood: this.AllGood,
                getOkay: this.getOkay
            }}>
                {this.props.children}
            </ProductContext.Provider>
        );
    };
}

const ProductConsumer = ProductContext.Consumer;

export {ProductAPI,ProductConsumer };