export const productArray = [
    {
        id: 0,
        title: "Metamorphosis",
        img1: 'img/Metamorphosis.png',
        img2: 'img/Metamorphosis2.png',
        price: 120,
        size: '35x32',
        info: "Made a quilt, then these pants. Could not stop thinking about you when I compiled the materials. I have angels all around me.",
        inCart: false,
        canOpenModel: true,
        count: 0,
        total: 0
    },
    {
        id: 1,
        title: "Inquiry",
        img1: 'img/InProduct2.png',
        img2: 'img/InProduct1.png',
        price: 100,
        size: '32x32',
        info: "I can never pay attention to the world around me. I hear your callings constantly, it's the only thing I can even think about. Nothing else matters. These blank faces and stares are becoming too normal for my liking. I will remain here for as long as it takes to find you.",
        inCart: false,
        canOpenModel: true,
        count: 0,
        total: 0
    },
    {
        id: 2,
        title: "Closed Garden",
        img1: 'img/ClosedGarden.png',
        img2: 'img/ClosedGarden2.png',
        price: 55,
        size: 'M',
        info: "They look just like us don't they? Sometimes I feel like I can't even talk because of how fucking weird I am. Blank stares down at flickering screens. Am I even the weird one anymore?",
        inCart: false,
        canOpenModel: true,
        count: 0,
        total: 0
    },
    {
        id: 3,
        title: 'Come With Me',
        img1: 'img/Come1.png',
        img2: 'img/Come2.png',
        price: 65,
        size: '31-32',
        info: "Fuck shorts! Nah just kidding I just look terrible in shorts, so I decided to make some for you guys. There are plenty of paths to take in this road we all have ahead of us. I didn't choose to walk alone. These people are just waiting to take advantage of me. My journey across purgatory is far from complete, yet I can feel your presence right next to me. You're the only one I truly need.",
        inCart: false,
        canOpenModel: true,
        count: 0,
        total: 0
    },
    {
        id: 4,
        title: "Amputee",
        img1: 'img/Amputee1.png',
        img2: 'img/Amputee2.png',
        price: 60,
        size: 'M-L',
        info: "Shout out to Mason for the help with this piece :) Hand sewed two shirt halves and the sleeve on some frankenstein shit. When traveling back and forth from home, sometimes you lose limbs. Especially when using the interdimensional portal. Shit happens I guess. I would give up my arms and legs just to be able to find you anyways.",
        inCart: false,
        canOpenModel: true,
        count: 0,
        total: 0
    },
    {
        id: 5,
        title: "The Wild",
        img1: 'img/TheWild1.png',
        img2: 'img/TheWild2.png',
        price: 55,
        size: 'Medium',
        info: "A lot of people want to get to know me more. I'm scared to let people in. Split some shirts that reminded me of you. Made watching Dragonball and reminiscing about playing Tenkaichi 2. ",
        inCart: false,
        canOpenModel: true,
        count: 0,
        total: 0
    }
];

export const productDetail = [
    {
        id: 0,
        title: "DEFAULT",
        img: '',
        price: 80,
        info: "DEFAULT",
        inCart: false,
        canOpenModel: true,
        count: 0,
        total: 0
    }
];

export const videoArray = [
    "CREEK.mp4",
    "SNOW.mp4",
    "fall.mp4",
    "field2.mp4",
    "Where.mp4"
];

export const poemList = [
    "I tell everyone about you. You’re beautiful. Look at you now. Where would I be without your love",
    "It’s hard to escape it. These people. These faces. Are all dead.",
    "This world you left behind. Nothing feels the same anymore. Too many facades and lies. Things aren’t the same without you.",
    "Feels like you’re always watching. Maybe you aren’t... All i know is I need you more than ever.",
    "I loved the times we had I always wondered what life would be like Without you."
];
