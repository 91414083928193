import React, {lazy, Suspense, Component} from 'react';
import {Switch, Route} from 'react-router-dom'
import { ProductConsumer } from '../context';
const Navbar = lazy(()=>import('./GlobalNav.js'));
const Home = lazy(()=>import('./Home.js'));
const Museum = lazy(()=>import('./Museum.js'));
const ProductList = lazy(()=>import('./ProductList.js'));
const Details = lazy(()=>import('./Details.js'));
const Cart = lazy(()=>import('./Cart/'));
const Default = lazy(()=>import('./Default.js'));
const Contact = lazy(()=>import('./Contact.js'));
const Footer = lazy(()=>import('./Footer.js'));
const Model = lazy(()=>import('./Model.js'));


export default class App extends Component{
      render(){
        return(
        <ProductConsumer>
            {value => {
                const {getOkay} = value;
                return(
                    <React.Fragment> 
                    <Suspense fallback = {
                        <div></div>
                    }>
                        <Navbar />
                    </Suspense>
                        
                        <br></br>
                        <br></br>
                    <Suspense fallback = {
                        <div></div>
                    }>
                        
                        <Switch>
                        <Route path = "/" exact component = {Home} />
                        <Route path = "/Museum" component = {Museum} />
                        <Route path = "/ProductList" component = {ProductList}/>
                        <Route path = "/Details" component = {Details} />
                        <Route path = "/Cart" component = {Cart} />
                        <Route path = "/Contact" component = {Contact} />
                        <Route component = {Default} />
                        </Switch>
                        
                    </Suspense>

                    <Suspense fallback = {
                        <div></div>
                    }>
                        <Model/>
                    </Suspense>
                        
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                    <Suspense fallback = {
                        <div></div>
                    }> 
                        <Footer/>
                    </Suspense>

                    </React.Fragment>
                    );
                }
            }
            </ProductConsumer>
        );
      }
    }

