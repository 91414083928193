import React from 'react';
import {isMobile} from 'react-device-detect'
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from './media/logo.mp4'
import fetcher from './Components/fetcher';
import AppClass from './Components/AppClass.js';

let didFetch = false;

const startFetch = () =>{
  if(didFetch === false){
    fetcher("GET");
    didFetch = true;
  }
}

function App (){
  startFetch();
  if(!isMobile){
    return(
      <div style = {{textAlign: "center"}}>
        <video className = "m-auto p-5" style = {{textAlign: "center", display: "block"}} autoPlay  loop  playsInline muted>
          <source src={logo} type = "video/mp4"/>
        </video>
        <h2>If you are reading this, It means you are on your computer.</h2>
        <h2>The desktop site is almost done. Please use your mobile device in the meantime.</h2>
        <h2>Thank you for understanding.</h2>
      </div>
    );
  }else{
    return(
        <AppClass/>
      );
    }
  }


export default App;
